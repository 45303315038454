var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.roles,"sort-by":"calories","page-count":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Perfis")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Novo Perfil ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"title_body"},[_c('span',{staticClass:"text_title"},[_vm._v(_vm._s(_vm.formTitle))]),_c('span',{staticClass:"btn_close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1)]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Perfil"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editPermission(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountKey)+" ")])]}}],null,true)},[_c('span',[_vm._v("Permissões")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Deletar")])])]}},{key:"no-data",fn:function(){return [_c('NoDataTable')]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando... ")]},proxy:true}])}),_c('MsgDelete',{on:{"delete-yes":function($event){return _vm.deleteItemConfirm()}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }